import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import PrimaryButton from "../Components/PrimaryButton";
import InputField from "../Components/InputField";
import shadow from "../assets/shadow.png";
import playButton from "../assets/play-gif/play.gif";
import playHover from "../assets/play-gif/playHover.gif";
import landing_page_reel from "../assets/video/landing_page_reel.mp4";
import close from "../assets/icons/close-icon.svg";
import closeHover from "../assets/icons/close-hover.svg";
import TextArea from "../Components/TextArea";
import { dataCarousel } from "../Components/Data/dataCarousel";
import SubmitButton from "../Components/SubmitButton";
import hero from  "../assets/gif-file/mainPageforWebsite1440.gif"

function Home() {
  const videoRef = useRef(null);
  const navigate = useNavigate();

  const [isPlaying, setIsPlaying] = useState(false);
  const [message, setMessage] = useState();
  const [email, setEmail] = useState();
  const [name, setName] = useState();
  const [subject, setSubject] = useState();
  const [play, setPlay] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const landing_page_reel = videoRef.current;
    if (landing_page_reel) {
      if (isPlaying) {
        landing_page_reel.play();
      } else {
        landing_page_reel.pause();
      }
    }
  }, [isPlaying]);

  
  const onTeamClick = () => {
    navigate("/team");
  };
  const onSeeMoreClick = () => {
    navigate("/work");
  };
  const onSendClick = () => {
    navigate("/");
  };
  const onClearClick = () => {
    setMessage("");
    setName("");
    setEmail("");
    setSubject("");
  };
  const onPlay = () => {
    setPlay(true);
    setIsPlaying(!isPlaying);
    videoRef.current.currentTime = 0;
  };
  const onClose = () => {
    setPlay(false);
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  };
  const renderCarousel = () => {
    return dataCarousel.map((item, index) => (
      <div className='image-wrapper' key={index}>
        <img src={item.image} alt='project image' className='image' />
        <img src={shadow} alt='shadow' className='shadow' />
        <div className="title-wrapper">
          <h3 className='project-title'>{item.title}</h3>
          <p className='project-description'>{item.description}</p>
        </div>
      </div>
    ));
  };

  return (
    <div className='home'>
      <div className='gif-section'>
        <img src={hero} className={play ? "hero-hide" : "hero"}/>
        <div className={play ? "play-hide" : "play-container"} onClick={onPlay}>
          <img src={playButton} alt='play button' className='play-button' />
          <img src={playHover} alt='play button' className='play-hover' />
        </div>
        <video
          src={landing_page_reel}
          ref={videoRef}
          controls
          className={play ? "video" : "video-hide"}></video>
        <div className='close-container'>
          <img
            src={close}
            alt='close button'
            className={play ? "close" : "close-hide"}
            onClick={onClose}
          />
          <img
            src={closeHover}
            alt='close button'
            className={play ? "close-hover" : "close-hide"}
            onClick={onClose}
          />
        </div>
      </div>
      <div className='team-section'>
        <div className='description'>

            <h2 className='title'>Hello there, </h2>

            <p className='text'>
            We are Final Version 2 - a post-production house based in Vancouver, Canada.
            Whether you're looking for eye-catching motion graphics, stunning visual effects,
            or comprehensive production services, we've got you covered!</p>

            <p className='text'>Dive into our website to learn more about us and the incredible projects
            we've been a part of. </p>




        </div>

        <PrimaryButton
          label={"Read More"}
          variant={"primary-btn"}
          onClick={onTeamClick}
        />
      </div>

      <div className='carousel-section'>
        <h2 className='carousel-title'>Peek at what's new!</h2>
        <div className='carousel-container'>
          <Carousel
            autoPlay={true}
            interval={3000}
            infiniteLoop={true}
            showArrows={false}
            showThumbs={false}
            showIndicators={true}
            showStatus={false}>
            {renderCarousel()}
          </Carousel>
          <PrimaryButton
            label={"See More"}
            variant={"primary-btn"}
            onClick={onSeeMoreClick}
          />
        </div>
      </div>
      <div className='contactus-section' id='contact'>
        <h2>Ready to connect?</h2>
        <form
          action='https://formspree.io/f/mbjevdyo'
          method='post'
          className='form'>
          <div className='name-email'>
            <InputField
              label={"Name"}
              type={"text"}
              onChange={(e) => setName(e.target.value)}
              name={"name"}
              value={name}
            />
            <InputField
              label={"Email"}
              type={"email"}
              onChange={(e) => setEmail(e.target.value)}
              name={"email"}
              value={email}
            />
          </div>
          <InputField
            label={"Subject"}
            type={"text"}
            onChange={(e) => setSubject(e.target.value)}
            name={"subject"}
            value={subject}
          />
          <TextArea
            label={"Message"}
            type={"textArea"}
            onChange={(e) => setMessage(e.target.value)}
            name={"message"}
            value={message}
          />
          <div className='buttons'>
            <SubmitButton
              label={"Send"}
              variant={"primary-btn"}
              onClick={() => onSendClick()}
            />
            <PrimaryButton
              label={"Clear"}
              variant={"secondary-btn"}
              onClick={onClearClick}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default Home;
