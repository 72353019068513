import React from 'react';
import button from "../assets/button.png"

function SubmitButton({ label, onClick, variant }) {
  return (
    <div className={variant}>
    <div className="div_wrapper">
      <div className="vertical_divs">
        <div className="yellow"></div>
        <div className="pink"></div>
      </div>
      <div className="blue"></div>
    </div>
    <img src={button} alt="hover" className="hover-button"/>
    <input type="submit" value={label} className="submit-label" onClick={onClick}/>
  </div>
  )
}

export default SubmitButton