import React from "react";
import ReelCard from "../Components/ReelCard.jsx";
import { dataReels } from "../Components/Data/dataReels";

function Reels() {
  const renderReels = dataReels.map((project, index) => (
    <ReelCard
      title={project.title}
      image={project.image}
      description={project.description}
      source={project.video_link}
      key={index}
    />
  ));

  return (
    <div className='reels'>
      <div className='reels-page-center'>
          <div className='description-section'>
            <h2 className='title'>Final reels</h2>
            <p className='text'>
              Check out some of the shots we've delivered for tv shows, features, and documentaries. To learn more about Final Version 2, check the About page!
            </p>
          </div>
          <div className='gallery-section'>
            <div className='gallery'>{renderReels}</div>
          </div>
      </div>
    </div>
  );
}

export default Reels;
