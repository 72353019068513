import React from "react";
import teamData from "../Components/Data/dataTeam";
import TeamCard from "../Components/TeamCard";
import { ReactComponent as SeparatorDots } from "../assets/icons/separator_dots.svg";

function Team() {
  return (
    <div className='team-page'>
      <div className='team-page-center'>
        <div className='description-section'>
          <div className='title'>Who, What & Why? </div>
          <div className='text'>
            <p>
            At Final Version 2 (FV2), we're all about crafting the next best image that goes live on <s>&nbsp;air&nbsp;</s> the internet. Our boutique studio came into existence when three creatives took the matter into their own hands and set up a shop catered to global productions and ambitious filmmakers like yourself!
            </p>
            <p>
            As a company, we stay ahead of industry trends by constantly exploring new avenues like virtual production and 3D visualizations to enhance your projects. Our commitment to innovation allows us to push boundaries!
            </p>
            <p>
            Having a team equipped with diverse skills at our disposal, you bet we've got the best set of hands to drive your project! Rest easy; we've got you covered!
            </p>

            <SeparatorDots className="separator_dots"/>
            <p>
              <ul className='services'>
                <li>
                  <h3 className='service-title'> Visual Effects </h3>
                  <p className='service-description'>
                    Let's fix those green screens, or how about little compositing magic? Perhaps, you're looking to show off your product in 3D space?
                  </p>
                </li>
                <li>
                  <h3 className='service-title'> Motion Graphics </h3>
                  <p className='service-description'>
                    Title sequences set the stage for the story. Those text bubbles help translate the story. UI/HUDs could be the story itself!
                  </p>
                </li>
                <li>
                  <h3 className='service-title'> Production</h3>
                  <p className='service-description'>
                    We can help you plan Virtual Production and take care of on-set VFX. Our kit bag is a kit room; get gear for your production needs!
                  </p>
                </li>
              </ul>
            </p>
            <SeparatorDots className="separator_dots"/>
          </div>
        </div>

        <div className='team-details-top'>

          <div className='team-list-title'> The Team </div>
          <div className='team-list-details'>
            {teamData.map((item) => (
              <TeamCard
                key={item.id}
                image={item.image}
                name={item.name}
                jobTitle={item.jobTitle}
                desc={item.description}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Team;
