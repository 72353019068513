import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import logo from '../assets/LogoRough.png';
import Navigation from './Navigation';

function Header() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handlePageChange = (page) => {
    closeMobileMenu();
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const onLogo = () => {
    navigate("/")
  }

  // Extract the current page name from the URL
  const getCurrentPageName = () => {
    const pathname = location.pathname;
    const currentPage = pathname.replace('/', '');
    return currentPage.charAt(0).toUpperCase() + currentPage.slice(1);
  };

  const activePage = getCurrentPageName();

  return (
    <div className='header'>
      <img src={logo} alt="logo" onClick={onLogo}/>
      <div className={isMobileMenuOpen ? 'navigation-mobile' : 'navigation-list'}>
        <Link
          to="/"
          onClick={() => handlePageChange('')}
        >
          <Navigation name="Home" className={activePage === '' ? 'yellow-active' : 'text'} />
        </Link>
        <Link
          to="/team"
          onClick={() => handlePageChange('About')}
        >
          <Navigation name="About" className={activePage === 'Team' ? 'blue-active' : 'text'} />
        </Link>
        {/* #################### Reels Navigation Hidden #####################################
        <Link
          to="/reels"
          onClick={() => handlePageChange('Reels')}
        >
          <Navigation name="Reels" className={activePage === 'Reels' ? 'blue-active' : 'text'} />
        </Link>
        #################### Reels Navigation Hidden ##################################### */}
        <Link
          to="/work"
          onClick={() => handlePageChange('Work')}
        >
          <Navigation name="Projects" className={activePage === 'Work' ? 'yellow-active' : 'text'} />
        </Link>
        <Link
          to="/#contact"  
          onClick={() => handlePageChange('Home')}
        >
          <Navigation name="Contact" className={activePage === 'Contact' ? 'yellow-active' : 'text'} />
        </Link>
      </div>
      <div className='hamburgermenu' onClick={toggleMobileMenu}>
        <div className={isMobileMenuOpen ? 'yellow-open' : 'yellow-line'}></div>
        <div className={isMobileMenuOpen ? 'blue-open' : 'blue-line'}></div>
        <div className={isMobileMenuOpen ? '' : 'pink-line'}></div>
      </div>
    </div>
  );
}

export default Header;
