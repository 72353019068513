import React from "react";
import { useState } from "react";
import { ReactComponent as Name_Tag } from "../assets/icons/name_tag.svg";

export default function TeamCard(props) {
  const [isVisible, SetIsVisible] = useState(false);
  const handleClick = () => {
    SetIsVisible(!isVisible);
  };
  return (
    <div className="team-card">
      <div className="image-container">
        <img src={props.image} />
        <div className="image-overlay"></div>
      </div>
      <div className="team-card-text">
        <div className="float-text">
          <p className="name">{props.name} <Name_Tag className="arrow" />  </p>
          <p className="job-title">{props.jobTitle}</p>
        </div>
        {/* ################### disabled Read more option ###############
        <p className="more-details">
          Read More
          {isVisible ? (
            <DetailsUpIcon className="arrow" onClick={handleClick} />
          ) : (
            <DetailsUpIcon className="arrow down" onClick={handleClick} />
          )}
        </p>
        <div className={`description-container ${isVisible ? "visible" : ""}`}>
          <div className="description">{props.desc}</div>
        </div>
        */}
      </div>
    </div>
  );
}
