function TextArea({ label, onChange, value, name }) {
  return (
    <div className='textarea-wrapper'>
      <label className="input">
        <span className='label'>{label}</span>
        <div className='input-type'>
          <div className='div_wrapper'>
            <div className='vertical_divs'>
              <div className='yellow'></div>
              <div className='pink'></div>
            </div>
            <div className='blue'></div>
          </div>
          <textarea onChange={onChange} value={value} name={name} rows="4" cols="50" required/>
        </div>
      </label>
    </div>
  );
}

export default TextArea;