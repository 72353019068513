import React from "react";
function Navigation({name, className}) {
  return (
    <div className="navigation">
      <div className="yellow-border">
        <div className="pink-border">
          <div className="blue-border">
            <div className={className}>{name}</div>
          </div>
        </div>
      </div>
      
    </div>
  )
}

export default Navigation