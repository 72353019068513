import React, { useState } from "react";
import { ReactComponent as CloseVideo } from "../assets/icons/closeVideo.svg";
import { ReactComponent as CloseHover } from "../assets/icons/hoverCloseVideo.svg";

const VideoModal = ({ onClose, source }) => {
  const [closeHover, setCloseHover] = useState(false);

  const handleClose = () => {
    onClose();
  };

  return (
    <div className="video-modal">
      <div className="video-modal-container">
        <div className="video-grid">
          <div
            className="cancel"
            onClick={handleClose}
            onMouseEnter={() => setCloseHover(true)}
            onMouseLeave={() => setCloseHover(false)}
          >
            {closeHover ? (
              <CloseHover className="close-video" />
            ) : (
              <CloseVideo className="close-video" />
            )}
          </div>
          <iframe
            className="responsive-iframe"
            src={source}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default VideoModal;
