import React from "react";
import button from "../assets/button.png"

function PrimaryButton({ label, onClick, variant }) {
  return (
    <div className={variant} onClick={onClick}>
      <div className="div_wrapper">
        <div className="vertical_divs">
          <div className="yellow"></div>
          <div className="pink"></div>
        </div>
        <div className="blue"></div>
      </div>
      <img src={button} alt="hover" className="hover-button"/>
      <div className="label">{label}</div>
    </div>
  )
}

export default PrimaryButton