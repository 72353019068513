import image1 from "../../assets/carouselImage/Poster_GFX_SP.png"
import image2 from "../../assets/carouselImage/Poster_VFX_TTZ.png"
import image3 from "../../assets/carouselImage/Poster_VFX_Alert.jpg"
import image4 from "../../assets/carouselImage/Poster_GFX_500Days.jpg"
export const dataCarousel = [
  {
    "id": 1,
    "title": "The Twilight Zone",
    "description": "The Twilight Zone: Created by Simon Kinberg, Jordan Peele, Marco Ramirez, Rod Serling. With Jordan Peele, David Epstein, Kelly Ann Woods, Mark Silverman",
    "image": image2,
  },
  {
    "id": 2,
    "title": "Alert: Missing Persons Unit",
    "description": "Alert: Missing Persons Unit is an American police procedural crime drama television series created by John Eisendrath and Jamie Foxx.",
    "image": image3,
  },
  {
    "id": 3,
    "title": "500 Days in the Wild",
    "description": "500 Days in the Wild: Directed by Dianne Whelan. With Dianne Whelan. A woman travels across Canada by hiking, biking, and paddling.",
    "image": image4,
  },
  {
    "id": 4,
    "title": "Sidney",
    "description": "Sidney is a 2022 American documentary film directed by Reginald Hudlin. The film is a portrait of the life and legacy of actor Sidney Poitier.",
    "image": image1,
  },


]