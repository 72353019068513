import React from "react";
import shadow from "../assets/workShadow.png";

function WorkCard({ image, title, description }) {
  return (
    <div className='work-card'>
      <div className='image-container'>
        <img src={image} alt="project's image" className='image' />
        {/* ##########################################################
        <img src={shadow} alt='shadow' className='shadow' />
        */}
        <h3 className='project-title'>{title}</h3>
        <p className='project-description'>{description}</p>
      </div>
    </div>
  );
}

export default WorkCard;
