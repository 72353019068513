import React from "react";
import { ReactComponent as InstagramIcon } from "../assets/icons/instagram.svg";
import { ReactComponent as YoutubeIcon } from "../assets/icons/youtube.svg";
import { ReactComponent as TwitterIcon } from "../assets/icons/twitter.svg";
import { ReactComponent as LinkedinIcon } from "../assets/icons/linkedin.svg";
import { ReactComponent as VIcon } from "../assets/icons/v.svg";
import ok from "../assets/OkayLetsGo.svg";

function Footer() {
  return (
    <div className='footer'>
      <div className='contact-wrapper'>
        <img src={ok} alt="ok let's go" />
        <a className='email' href='mailto:contact@finalversion2.com'>
          contact@finalversion2.com
        </a>
      </div>
      <div>
        <ul>
          <li>
            <a
              href='https://www.instagram.com/finalversion2/'
              target='_blank'
              rel='noreferrer'>
              <InstagramIcon />
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/final-version-2/"
              target="_blank"
              rel="noreferrer">
              <LinkedinIcon />
            </a>
          </li>
          {/* <li>
            <a href="https://www.youtube.com/" target="_blank" rel="noreferrer">
              <YoutubeIcon />
            </a>
          </li>
          <li>
            <a href="https://www.twitter.com/" target="_blank" rel="noreferrer">
              <TwitterIcon />
            </a>
          </li>
          <li>
            <a href="https://www.vimeo.com/" target="_blank" rel="noreferrer">
              <VIcon />
            </a>
          </li> */}
        </ul>
      </div>
      <div>
        <p className='copyright'>© 2024 Final Version 2. All rights reserved.</p>
      </div>
    </div>
  );
}

export default Footer;
