import React from 'react';

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./app.scss";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Home from "./pages/Home";
import Work from "./pages/Work";
import Reels from "./pages/Reels";
import Team from './pages/Team';
import ScrollToTop from './Components/ScrollToTop';

function App() {
  const queryClient = new QueryClient()

  return (
    <div className="App">
       <BrowserRouter>
        <Header />
        <QueryClientProvider client={queryClient}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/reels" element={<Reels />} />
            <Route path="/work" element={<Work />} />
            <Route path="/team" element={<Team />} />
          </Routes>
        </QueryClientProvider>
        <ScrollToTop />
        <Footer />

      </BrowserRouter>
    </div>
  );
}

export default App;
