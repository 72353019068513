import React, { useState } from "react";
import shadow from "../assets/workShadow.png";
import play from "../assets/icons/play.svg";
import playHover from "../assets/icons/playHover.svg";
import VideoModal from "./VideoModal";

function ReelCard({ image, title, description, source }) {
  const [modal, setModal] = useState(false);

  const playVideo = () => {
    setModal(!modal);
  };
  const closeModal = () => {
    setModal(false);
  };

  return (
    <div className='reel-top'>
      <div className='reel-card'>
        <div className='image-container'>
          <img src={image} alt="project's image" className='image' />
          <img src={shadow} alt='shadow' className='shadow' />
          <div className='play-button-clickable' onClick={playVideo}>
            <img src={play} alt='play button' className='play-button' />
            <img
              src={playHover}
              alt='play hover button'
              className='play-hover'
            />
          </div>

          {modal ? (
            <div className='video-container'>
              <VideoModal onClose={closeModal} source={source} />
            </div>
          ) : null}
          <h3 className='project-title'>{title}</h3>
          {/* <p className="project-description">{description}</p> */}
        </div>
      </div>
    </div>
  );
}

export default ReelCard;
