import gfx from "../../assets/reels/gfx.png"
import vfx from "../../assets/reels/vfx.png"

export const dataReels = [
  {
    "id": 1,
    "title": "Motion Graphics",
    "description": "Project's description",
    "image": gfx,
    "video_link": "https://www.youtube.com/embed/4HUKxUBTVXY",
    "type": "GFX"
  },
  {
    "id": 2,
    "title": "Visual Effects",
    "description": "Project's description",
    "image": vfx,
    "video_link": "https://www.youtube.com/embed/0E_Ch6yT298",
    "type": "VFX"
  },

]