import AI from "../../assets/works/Thumbnail_GFX_AI.png"
import SP from "../../assets/works/Thumbnail_GFX_SP.png"
import WWR from "../../assets/works/Thumbnail_GFX_WWR.png"
import HBU from "../../assets/works/Thumbnail_VFX_HBD.png"
import PKO from "../../assets/works/Thumbnail_VFX_PKO.png"
import TTZ from "../../assets/works/Thumbnail_VFX_TTZ.png"
import DAY from "../../assets/works/Thumbnail_GFX_days.jpg"
import ALT from "../../assets/works/Thumbnail_VFX_alert.jpg"
import POD from "../../assets/works/Thumbnail_VFX_podium.jpg"
import FAR from "../../assets/works/Thumbnail_GFX_farm.jpg"


export const dataWork = [
  {
    "id": 1,
    "title": "",
    "description": "",
    "image": ALT,
    "type": "VFX"
  },
  {
    "id": 2,
    "title": "",
    "description": "",
    "image": DAY,
    "type": "GFX"
  },
  {
    "id": 3,
    "title": "",
    "description": "",
    "image": POD,
    "type": "VFX"
  },
  {
    "id": 4,
    "title": "",
    "description": "",
    "image": FAR,
    "type": "GFX"
  },
  {
    "id": 5,
    "title": "",
    "description": "",
    "image": SP,
    "type": "GFX"
  },
  {
    "id": 6,
    "title": "",
    "description": "",
    "image": HBU,
    "type": "VFX"
  },
  {
    "id": 7,
    "title": "",
    "description": "",
    "image": PKO,
    "type": "VFX"
  },
  {
    "id": 8,
    "title": "",
    "description": "",
    "image": AI,
    "type": "GFX"
  },
  {
    "id": 9,
    "title": "",
    "description": "",
    "image": WWR,
    "type": "GFX"
  },
  {
    "id": 10,
    "title": "",
    "description": "",
    "image": TTZ,
    "type": "VFX"
  },
]